import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Menu from '../../components/navigation';
import ProjectsText from '../../components/projects/text';
import ProjecsGallery from '../../components/projects/gallery';
import ProjectItem from '../../components/projects/item';
import Image from '../../components/projects/image';

// import logo from '../../images/projects/leidenbergerhof/logo.svg';
import logo from '../../images/projects/leidenbergerhof/logo.png';
import img1 from '../../images/projects/leidenbergerhof/grafik.svg';

import mockup from '../../images/projects/leidenbergerhof/mock-up.jpg';
import img2 from '../../images/projects/leidenbergerhof/img2.jpg';
import img22x from '../../images/projects/leidenbergerhof/img2@2x.jpg';
import img3 from '../../images/projects/leidenbergerhof/img3.jpg';
import img32x from '../../images/projects/leidenbergerhof/img3@2x.jpg';
import img4 from '../../images/projects/leidenbergerhof/img4.jpg';
import img42x from '../../images/projects/leidenbergerhof/img4@2x.jpg';
import img5 from '../../images/projects/leidenbergerhof/img5.jpg';
import img52x from '../../images/projects/leidenbergerhof/img5@2x.jpg';

const title =
	'Corporate Design Relaunch für den Leidenbergerhof in Furschweiler';
const text =
	'Das Logo des Bioland-Betriebes „Leidenbergerhof“ zeigt aus dessen Perspektive einen malerischen Ausblick auf den Leidenberg selbst. Die handgemachten Illustrationen dienen dabei als Vorgeschmack auf eine naturnahe Idylle, welche mit Mensch und Tier in Einklang gebracht wird. Herzlichkeit, Naturverbundenheit und vor allem eine liebevolle, artgerechte Tierhaltung sind Qualitäten, die den „Leidenbergerhof“ auszeichnen und konzeptuell umgesetzt wurden.';

/**
 * Page
 */
const Page = () => {
	return (
		<Layout>
			<SEO title="" />
			<Menu selected="projekte" />
			<ProjectsText text={text} title={title} />
			<div className="page-width align-center"></div>
			<ProjecsGallery>
				<ProjectItem>
					<img src={logo} className="leidenbergerhof-logo" alt="" />
				</ProjectItem>
				<ProjectItem>
					<Image src={mockup} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img2} src2={img22x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img3} src2={img32x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img4} src2={img42x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img5} src2={img52x} />
				</ProjectItem>
				<ProjectItem>
					<img src={img1} alt="" />
				</ProjectItem>
			</ProjecsGallery>
		</Layout>
	);
};

export default Page;
